import React from "react";
import "./Footer.css";
import {
  BsFillTelephoneFill,
  BsClockFill,
  BsFillPinMapFill,
} from "react-icons/bs";
import { MdEventAvailable } from "react-icons/md";
const Footer = () => {
  return (
    <div className="footer-section">
      <div className="footer-row">
        <div className="footer-column footer-left-column">
          <div className="footer-icon"></div>
          <h3 className="footer-column-heading"> Llamanos</h3>
          <h4 className="footer-column-subheading">
            {" "}
            <BsFillTelephoneFill color="white" size={"32px"} />
            <p> 22 555 0727</p>
          </h4>
          <h4 className="footer-column-subheading"> 22 556 3581</h4>
          <h4 className="footer-column-subheading"> 22 555 0638</h4>
        </div>
        <div className="footer-column footer-middle-column">
          <div className="footer-icon"></div>
          <h3 className="footer-column-heading">Horario de Atencion</h3>
          <BsClockFill color="white" size={"32px"} />
          <h4 className="footer-column-subheading">
            Lunes a Viernes 08:30 a 18:45.
          </h4>
          <h4 className="footer-column-subheading">Sábados 09:00 a 12:45</h4>
        </div>
        <div className="footer-column footer-right-column">
          <div className="footer-icon"></div>
          <h3 className="footer-column-heading">Visitanos</h3>
          <BsFillPinMapFill color="white" size={"32px"} />
          <h4 className="footer-column-subheading">Llano Subercaseaux 3811</h4>
        </div>
      </div>
    </div>
  );
};

export default Footer;
